import React, { Component } from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';

import T1Planet from '../images/t1Planet.png'
import PoweredByAi from '../images/poweredByAi.svg'
import RefinedDesign from '../images/refinedDesign.png'
import LibertyRing from '../animations/libertyRing.json'

var lottie

export default class PageNotFoundPage extends Component {
  constructor(props) {
    super(props)
    this.animationIsAttached = false
  }

  componentDidMount() {
    lottie = require('lottie-web')
    this.attachAnimation()
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: LibertyRing,
      }

      lottie.loadAnimation(animationProperties)
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <title>Truly | Phone System</title>
        </Helmet>

        <WrapperBackground
          color={colors.trulyDark}
          background='telephony1'
        >
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
              Phone System
              </PageTitle2>
              <PageTitle as="p" color={colors.white} mb="1.5rem" center>
              Enterprise Technology, Built From The Ground Up
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperBackground
          color={colors.trulyDark}
          background='telephony2'
          gradientType='light'
        >
          <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width={[1, 1/2]}>
              <CenteredImg src={T1Planet} alt="Tier 1"/>
            </Box>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem">
              GLOBAL INFRASTRUCTURE
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                No amount of bells and whistles can make up for a dropped call. Truly is the only modern voice platform that owns and operates its own global infrastructure, with direct connects into a heavily curated pool of Tier-1 incumbent carriers.
              </Large>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                Our customers report 5x fewer call quality issues, 10x improvement in satisfaction ratings and 1 Full Time Employee in time savings from less support triage.
              </Large>

            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid color='#292929'>
          <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
              SCALABLE ADMINISTRATION
              </Title3>
              <Large as="p" color={colors.white} mb="1.5rem">
              Truly was designed to decentralize phone system administration, eliminating time consuming setup, configuration and support cycles.
              </Large>
              <Large as="p" color={colors.white} mb="1.5rem">
              Integration into your identity server allow for automated provisioning. Sophisticated governance rules enable front-line managers to make changes to their setup without impacting other teams.  Rich APIs enable you to programmatically customize call behavior with just a few lines of code.
              </Large>
            </Box>
            <Box width={[1/2, 1/2]}>
              <CenteredImg src={PoweredByAi} alt="AI"/>
            </Box>
          </Section>
        </WrapperSolid>

        <WrapperSolid>
          <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
            <Box width={[1/2, 1/2]} pb="2rem">
              <CenteredImg src={RefinedDesign} alt="Refined Design"/>
            </Box>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
              WORKS WITH WHAT YOU HAVE
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
              With more than 10 different apps and connectors, Truly can be deployed either as a complete replacement or complement to your existing voice infrastructure.
              </Large>
              <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
              Compatible with your SIP devices (desk phones, conference phones), computers (Windows, Mac, Linux, Chrome OS), and mobile devices (iOS, Android).
              </Large>
            </Box>
          </Section>
        </WrapperSolid>

      </Default>
    )
  }
}

const CenteredImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`
